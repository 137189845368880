.form_section {
  z-index: 2;
  background: radial-gradient(
    87.39% 87.39% at 50.19% 12.61%,
    rgba(0, 2, 42, 0.47) 0%,
    rgba(21, 20, 26, 0.5) 100%
  );
  border-radius: 32px;
}
.form_head_title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 53px;
  color: #ffffff;
}

.form_text_input {
  background: transparent;
}

.button_send_form {
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  color: #92140c;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.footer_section {
  z-index: 2;
  background-color: #00000d;
  color: #ffffff;
}

.footer_column {
  z-index: 2;
  height: 215px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 2em;
  padding: 1em 0;
}
.logo_footer {
  width: 144px;
  height: 214px;
}

.links {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.list-item {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #ffffff;
  text-decoration: none;
}

.footer_column_title::after {
  content: "";
  background-color: #92140c;
  position: absolute;
  width: 140px;
  left: initial;
  top: 0;
  margin: 0 auto;
  display: flex;
  height: 4px;
  border: 2px solid #92140c;
  border-radius: 10px;
}

.social_media_icon {
  width: 45px;
  height: 45px;
  margin: 5px;
}

.footer-elements{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .footer-elements{
    flex-direction: column;
  }
  .footer_column{
    text-align: center !important;
    align-items: center;
  }
  .footer_column_title::after{
    left: 50%;
    transform: translateX(-50%);
  }
}
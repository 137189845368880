.solution_section {

  /* background-image: url("./Assets/banner_bg.svg");
  background-size: cover;
  height: 700px; */
  background-color: #00000d;

  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #ed2213;
  }
  .second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
  }
  .second-header {
    h1 {
      font-family: "Hind";
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
    }
    p {
      font-family: "Hind";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      color: #ffffff;
    }
  }
  .solution > div{
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
  }
  .solution-imgs {
    width: 50px;
    height: 50px;
    margin-bottom: 14px;
  }
  .solution-name {
    font-size: 18px;
    font-weight: 600;
  }
  .solution-more {
    text-align: right;
    img {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }
}

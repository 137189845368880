.btn-newsletter {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 15px;
  background: linear-gradient(100.56deg, #00022a -16.45%, #92140c 120.89%);
  background-size: 100%, 100%, 0%, 0%;
  transition: background .01s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
.btn-newsletter:hover{
  background-color:  #00022a;
  background-size: 0%, 100%, 0%, 0%;
}
.newsletter-text-button {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #dddddd;
}

.partners-section {
  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: rgb(69, 68, 68);
  }

  .image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0.5); /* Adjust the opacity and color as needed */
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .img-fluid {
    width: 130px;
  }

  .image-container:hover .image-cover {
    opacity: 0;
  }

  .left-box {
    border-right: 1px solid;
  }
}
.aside_section {
  height: 1700px;
  background-image: url("./Assets/bg_aside.svg");
  background-size: cover;
  border-radius: 20px;
}
.post_blog_title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #00022a;
}

.post_author_name {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 2, 42, 0.7);
}

.post_info {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 2, 42, 0.7);
}

.post-author-info {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}

.post-author-bio {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 123.1%;
  color: #ffffff;
}

.post-page-post-section {
  border-left: 2px solid #00022a;
  font-family: "Hind";
  color: #00022a;
}
.post-page-post-section :is(h1, h2, h3, h4, h5, h6) {
  font-style: normal;
  font-weight: 600;
  line-height: 154.1%;
}
/* .post-page-post-section :is(h1, h2)::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-color: #69b6d5;
  left: -10.5%;
  top: -150%;
} */
.post-page-post-section p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 154.1%;
}

.post-page-post-section img {
  width: 100%;
  height: 100%;
}

.post-page-title-newsletter {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  padding-bottom: 20px;
}

.product-page-main-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  background: linear-gradient(90.37deg, #92140c 32.13%, #00022a 65.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.product-page-card-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
}
.product-page-card-parag {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138.1%;
  color: #ffffff;
}

/* .product-page-card-button {
  height: 50px;
  width: 150px;
  left: 0px;
  top: 0px;
  border-radius: 15px;
  background: #f5f5fc;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
}

.product-page-card-button-text {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 11.7469px;
  line-height: 19px;
  background: linear-gradient(92.93deg, #92140c 0%, #00022a 102.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
} */

.inc_section {
  /* z-index: -1; */
  height: auto;
  background-size: cover;
  background-image: url("./Assets/bg_how_it_works.svg");
}
/* .red_title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  
} */
.light_title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: white;
}

.banner_section {
  background-image: url("./Assets/banner_bg.svg");
  background-size: cover;
  height: 700px;
}

.first-title-banner {
  color: #ffffff;
  font-family: Hind;
  font-size: 50px;
  font-weight: 700;
  line-height: 75px;
}
.banner-btn {
  height: 50px;
  width: 150px;
  background: #f5f5fc;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
}
@media only screen and (min-width: 576px) and (max-width: 1300px) {
  .first-title-banner {
    color: #ffffff;
    font-family: Hind;
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .first-title-banner {
    color: #ffffff;
    font-family: Hind;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
  }
}

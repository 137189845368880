/* .section-team-container {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

.section-title-side-banner{
  min-width:100px;
  display: flex;
  margin-left: 3em;
  flex: 0;
  direction:column;
  align-items:center;
  justify-content:center;
  background: linear-gradient(0deg, #00022a, black ,rgb(85,12,12));
}

.section-title-side-banner-text{
  transform:rotate(-90deg);
  color:white; 
  font-weight:bold;
  font-size:2em;
  justify-content: center;
  white-space: nowrap;
}

.team_section {
  /* z-index: 1; */
  flex: 1;
  padding: 0 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px );
  justify-content: start;
  gap: 2em;
}
.team_section .profile-card{
  width: 100%;
  position: relative;
}
.sqr-img-container {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.sqr-img-container::after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.sqr-img-container img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
@media screen and (max-width: 1023px) {
  .team_section {
    grid-template-columns: repeat(auto-fit, 175px );
  }
}
@media screen and (max-width: 480px) {
  .linkedin-link{
    display: none;
  }
  .team_section {
    grid-template-columns: repeat(auto-fit, 100px );
    font-size: 12px;
  }
}

.first-title-section-team {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  color: #92140c;
}
.second-title-section-team {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  color: #171c27;
}


.plus {
  cursor: pointer;
}
/* 
@media screen and (max-width: 992px) {
  .section-team-container {
    width: 1000px !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }
  svg {
    display: inline-block !important;
    width: 1000px !important;
    height: auto !important;
    left: 0;
  }
} */

.team_picture {
  border-radius: 5px;
  width: 100%;
  /* padding: 7px; */
}

.card-content {
  /* opacity: 0.95 !important; */
  min-width: min(100vw, 318px);
  position: absolute;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, top 0.3s ease-out, transform 0.3s ease-out;
  transform: scale(1);
  opacity: 0;
  padding-top: 20px;
  /* top: -20px; */
  top: 0px;
  left: -24px;
  border-radius: 20px !important;
  border: none !important;
  z-index: 99;
}

.team-member-name {
  font-family: Hind;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: start;
}
.team-member-job {
  font-family: Hind;
  font-size: 1.1rem;
  text-align: start;
  /* font-weight: 500; */
}
.team-member-bio {
  font-family: Hind;
  font-weight: 400;
}
div._blogs {
  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    font-size: 22px;
    color: #92140c;
  }
  .second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #0d0c3a;
    font-size: 2.5rem;
  }
  .catBtns {
    padding: 4px 20px;
    margin: 6px;
    border: none;
    background: #00022a22;
    font-weight: 500;
  }
  .catBtns.active {
    background: rgba(0, 2, 42, 1);
    color: #fff;
  }
  .blog-imgs {
    object-fit: cover;
    width: 100%;
    height: 180px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .category-text {
    color: rgba(146, 20, 12, 1);
    font-weight: 700;
  }
  .article-tilte {
    font-weight: 700;
  }
}
.hero_section {
  z-index: 2;

  .canvasEarth {
    height: 800px;
  }
  .main_title_hero {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    color: #00022a;
  }
  .main_para_hero {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #00022a;
  }
  .hero_buttonkk {
    height: 50px;
    width: 150px;
    border: none;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .red_btn {
    background: linear-gradient(100.56deg, #00022a -16.45%, #92140c 120.89%);
    background-size: 100%, 100%, 0%, 0%;
    transition: background .01s ease-in-out;
    color: #dddddd;
    text-decoration: none;
  }

  .red_btn:hover {
    background-color:  #00022a;
    background-size: 0%, 100%, 0%, 0%;
    color: #dddddd;
    text-decoration: none;
    cursor: pointer;
  }
  .white_btn {
    background: #f5f5fc;
    color: #92140c;
    text-decoration: none;
  }
  .white_btn:hover {
    background: #f5f5fc;
    color: #92140c;
    text-decoration: none;
  }
  .header-right-arrow {
    width: 30px;
    padding: 0 9px 6px 0;
  }
  .short_tour {
    background-image: url("./Assets/circles_under_video.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    .img_short_tour {
      width: 100%;
      box-shadow: 0 0 6px #a1a0a0;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 1300px) {
    .canvasEarth {
      height: 650px;
    }
  }
  @media only screen and (max-width: 576px) {
    .canvasEarth {
      height: 450px;
    }
    .hero_buttonkk {
      height: 50px;
      width: 350px;
      border: none;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
      border-radius: 15px;
    }
  }

}
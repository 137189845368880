.case-studies-section {
  .title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #0d0c3a;
  }
  .blog-imgs {
    object-fit: cover;
    width: 100%;
    height: 180px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .category-text {
    color: rgba(146, 20, 12, 1);
    font-weight: 700;
  }
  .article-tilte {
    font-weight: 700;
  }
}
.blog-page-main-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 900;
  font-size: 160px;
  line-height: 174px;
  color: #000000;
  background: linear-gradient(90.51deg, #00022a -0.82%, #92140c 114.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blog-page-second-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 776px) {
  .blog-page-main-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 900;
    font-size: 150px;
    line-height: 125px;
    color: #000000;
    background: linear-gradient(90.51deg, #00022a -0.82%, #92140c 114.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .blog-page-second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }
}

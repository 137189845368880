@import url("https://fonts.googleapis.com/css2?family=Hind:wght@700&display=swap");

.navbar {
  background-color: #ffffff;
  z-index: 1;
}
li {
  font-family: "Hind", sans-serif;
  padding: 0 1% 0 1%;
}
ul li a {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #00022a;
  text-decoration: none;
}
ul li a:hover {
  color: #92140c;
  text-decoration: none;
}

.active-style {
  color: #92140c;
}

.bttn {
  background: linear-gradient(100.56deg, #00022a -16.45%, #92140c 120.89%);
  background-size: 100%, 100%, 0%, 0%;
  transition: background .01s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 3.5em; */
  font-weight: normal;
  line-height: 25px;
  min-height: 40px;
  min-width: 136px;
  /* font-size: 1em; */
  text-decoration: none;
}
.bttn:hover{
  background-color:  #00022a;
  background-size: 0%, 100%, 0%, 0%;
}
@media only screen and (max-width: 650px) {
  .logo-navbar {
    width: 170px;
    height: 80px;
  }
}

@media only screen and (max-width: 570px) {
  .logo-navbar {
    width: 140px;
    height: 65px;
  }
}

div.faqs {
  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    font-size: 22px;
    color: #92140c;
  }
  .second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #0d0c3a;
    font-size: 2.5rem;
  }
  .icons {
    height: 15px;
    width: 15px;
    transform: rotate(90deg);
    margin-right: 15px;
  }
  .card {
    border: none;
    .card-header {
      background-color: inherit;
      border: none;
      a {
        font-weight: bold;
      }
    }
    &:has(div.show) {
      background: rgb(245, 244, 244);
      border-radius: 32px;
      .icons {
        transform: rotate(270deg);
      }
    }
  }
}
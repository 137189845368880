
.newsletter-section {
  .first-title-newsletter {
    background-image: linear-gradient(100.56deg, #00022a -16.45%, #92140c 120.89%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
    font-size: 20px;
  }
  .newsletter-box .newsletter-input {
    max-width: 600px;
  }
}
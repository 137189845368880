.x3_section {

  /* font-family: monospace; */
  color: #0d0c3a;
  font-weight: bold;

  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    font-size: 22px;
    color: #92140c;
  }
  .second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #0d0c3a;
    font-size: 2.5rem;
  }
  
  .nav-tabs .nav-link {
    color: inherit;
    cursor: pointer;
    border-color: #dee2e6 #dee2e6 #fff;

    &.active, &:hover {
      background-color: #dedede;
      color: black;
    }
  }

  .box-row {
    display: flex;
    min-height: 420px;
    flex-direction: row-reverse;

    .box {
      height: auto;
      border: 1px solid #ccc;
      padding: 18px;
    }

    .fixed-box {
      width: 100px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      span {
        transform: rotate(-90deg);
      }
      &.active, &:hover {
        background-color: #dedede;
        color: black;
      }
    }

    .remaining-box {
      flex: 1;
      text-align: left;
      align-content: end;
    }
    
    /* Media query for mobile devices */
    @media (max-width: 768px) {

      flex-direction: column; 

      .box {
        width: 100%; /* Each box takes full width */
      }

      .fixed-box {
        width: 100%;
        span {
          transform: rotate(0deg);
        }
      }

      .remaining-box {
        text-align: center;
        align-content: unset;
      }

    }

  }

}
.why-dsl-section{
  .first-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    font-size: 22px;
    color: #92140c;
  }
  .second-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    color: #0d0c3a;
    font-size: 2.5rem;
  }

  .icons {
    height: 75px;
    width: 75px;
    /* padding: 1em; */
    align-self: center;
    /* background-color: #92140c; */
    border-radius: 50%;
    /* box-shadow: 2px 2px 8px #000000, -1px -1px 8px rgba(255, 255, 255, 0.25); */
  }

  .card-title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 123.1%;
    text-align: center;
    /* color: #0d0c3a; */
    color: #000000;
  }

  .card-text {
    font-family: "Hind";
    font-weight: 400;
    font-style: normal;
    color: #0d0c3a;
  }
}
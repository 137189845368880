.single-solution {
  background-image: url("./Assets/bg.svg");
  background-size: cover;
  background-color: #000000;
  color: #fff;
  .solution-logo {
    background: #fff;
    padding: 14px;
    border-radius: 50%;
  }
  .case_study_blogs {
    .blog-imgs {
      object-fit: cover;
      width: 100% !important;
      height: 180px;
      border-radius: 8px;
      margin-bottom: 10px;
    }
    .category-text {
      color: #ac8800;
      font-weight: 700;
    }
    .article-tilte {
      font-weight: 700;
    }
  }
  
  .box-row {
    display: flex;
    min-height: 340px;
    margin: 30px auto;
    flex-direction: row-reverse;

    .box {
      height: auto;
      border: 1px solid #ccc;
      padding: 18px;
    }

    .fixed-box {
      width: 100px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      span {
        transform: rotate(-90deg);
      }
      &.active, &:hover {
        background-color: #dedede;
        color: black;
      }
    }

    .remaining-box {
      flex: 1;
      text-align: left;
      align-content: end;
    }
    
    /* Media query for mobile devices */
    @media (max-width: 768px) {

      flex-direction: column; 

      .box {
        width: 100%; /* Each box takes full width */
      }

      .fixed-box {
        width: 100%;
        span {
          transform: rotate(0deg);
        }
      }

      .remaining-box {
        text-align: center;
        align-content: unset;
      }

    }

  }
}
.story_section {
  background-image: url("./Assets/about_bg.svg");
  background-size: cover;
}
.about-page-main-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  text-align: center;
  background: linear-gradient(90.37deg, #92140c 32.13%, #00022a 65.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.about-page-story-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 48px;
  color: #ffffff;
}
.about-page-parag {
  font-family: "Hind";
  font-style: normal;
  color: #ffffff;
}

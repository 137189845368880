.team_picture {
  border-radius: 5px;
  width: 100%;
  /* padding: 7px; */
}

.modal-content {
  /* opacity: 0.95 !important; */
  min-width: min(100vw, 595px);
  border-radius: 20px !important;
  border: none !important;
}

.team-member-name {
  font-family: Hind;
  font-size: 1.25rem;
  font-weight: bold;
}
.team-member-job {
  font-family: Hind;
  font-size: 1.1rem;
  /* font-weight: 500; */
}
.team-member-bio {
  font-family: Hind;
  font-weight: 400;
}

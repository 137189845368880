.contact_page {
  z-index: 2;
  height: auto;
  /* background-image: url("./Assets/contact_bg.svg");
  background-size: cover; */
  background-color: #00000d;
}

.main_title,
.second_title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
}

.contact_page_form_section {
  z-index: 2;
}

@media only screen and (max-width: 650px) {
  .main_title {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 53px;
  }
}
